import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable( {
  providedIn: 'root'
} )
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super( router, keycloak );
  }

  async isAccessAllowed( route: ActivatedRouteSnapshot,
                         state: RouterStateSnapshot ): Promise<boolean | UrlTree> {

    // Force the user to log in if currently unauthenticated.
    if ( !this.authenticated ) {
      let redirectUri = window.location.origin + state.url;
      console.log( 'redirectUri' );
      console.log( redirectUri );
      await this.keycloak.login( {
        redirectUri: redirectUri,
      } );
    }

    console.log( 'this.roles' )
    console.log( this.roles )

    return Promise.resolve( true );
  }

}
