<div class="p-3 flex justify-content-between bg-white">
  <div class="flex">
    <div class="flex align-items-center">
      <img routerLink="/"
           src="assets/logo.png"
           alt="KUSTEC Logo"
           height="65">
      <img routerLink="/"
           src="assets/majumi_logo_ai_k.png"
           alt="majumi logo"
           height="80">

      <div class="ml-2">
        <div class="text-xl text-gray-600">{{'basic.app.title' | translate}}</div>
        <div class="text-3xl font-bold">{{'basic.app.name' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="flex align-content-center">
    <div class="flex align-items-center">
      <app-lang-switcher></app-lang-switcher>
    </div>
    <div class="flex align-items-center">
      <app-avatar *ngIf="isLoggedIn"></app-avatar>
      <button *ngIf="!isLoggedIn"
              (click)="login()"
              pButton
              label="Login"
              class="p-button p-button-outlined">
      </button>
    </div>
  </div>
</div>
<div *ngIf="ks.isUserInRole('kustec_admin')">
  <app-menubar></app-menubar>
</div>
