<div class="flex gap-3">
  <div class="flex flex-grow-1">
    <div class="w-full bg-white p-3 border-round-xl">
      11111111111111111111
      11111111111111111111
      11111111111111111111
    </div>
  </div>
  <div class="flex flex-grow-1">
    <div class="w-full bg-white p-3 border-round-xl">
      222222222222222222
      222222222222222222
      222222222222222222
    </div>
  </div>
  <div class="flex flex-none">
    <div class="w-full bg-white p-3 border-round-xl">
      <span class="font-bold text-lg text-primary">
        {{ 'pages.start.supervisors' | translate }}
      </span>
      <app-supervisor></app-supervisor>
      <app-supervisor></app-supervisor>
    </div>
  </div>
</div>

<div class="mt-6">

  <p-panel *ngIf="keycloakService.isLoggedIn()">

    <div *ngIf="!systems || (systems && systems.length === 0)">
      {{ 'pages.start.no-systems-available' | translate }}
    </div>

    <p-table *ngIf="systems && systems.length > 0"
             #dt
             [value]="systems"
             selectionMode="single"
             dataKey="systemNumber"
             [paginator]="true"
             [rows]="20"
             [rowsPerPageOptions]="[20, 50, 100]"
             styleClass="p-datatable-striped">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 10%" pSortableColumn="systemNumber">
            {{ 'pages.start.system-number' | translate }}
            <p-sortIcon field="systemNumber"></p-sortIcon>
          </th>
          <th style="width: 20%"
              pSortableColumn="customerName">
            {{ 'pages.start.customer' | translate }}
            <p-sortIcon field="customerName"></p-sortIcon>
          </th>
          <th style="width: 5%"
              pSortableColumn="countryIsoCode" style="min-width: 14rem">
            {{ 'pages.start.country' | translate }}
            <div class="flex justify-content-between align-items-center">
              <p-sortIcon field="countryIsoCode"></p-sortIcon>
            </div>
          </th>
          <th><!-- Bild--></th>
          <th><!--Anlagentyp-->{{ 'pages.start.system-type' | translate }}</th>
          <th style="width: 5%"><!--Status--></th>
        </tr>
        <tr>
          <th>
            <input pInputText
                   type="text"
                   class="p-inputtext-sm"
                   (input)="dt.filter(print($event), 'systemNumber', 'contains')"
                   [placeholder]="'pages.start.search-by-system-number' | translate">
          </th>
          <th>
            <input pInputText
                   type="text"
                   class="p-inputtext-sm p-column-filter"
                   (input)="dt.filter(print($event), 'customerName', 'contains')"
                   [placeholder]="'pages.start.search-by-customer' | translate">
          </th>
          <th></th>
          <th></th>
          <th>
            <input pInputText
                   type="text"
                   class="p-inputtext-sm p-column-filter"
                   (input)="dt.filter(print($event), 'systemType', 'contains')"
                   [placeholder]="'pages.start.search-by-system-type' | translate">
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-coolingSystem let-expanded="expanded">
        <tr
          (click)="goToDetailPage(coolingSystem)"
          class="clickable-row cursor-pointer"
        >
          <td>
            <a class="font-bold">
              {{ coolingSystem.systemNumber }}
            </a>
          </td>
          <td>
            {{ coolingSystem.customerName }}
          </td>
          <td>
            <span class="p-column-title">Country</span>
            <span [class]="'fi fi-' + coolingSystem.countryIsoCode.toLowerCase()"></span>
            <span class="image-text"> {{ coolingSystem.countryIsoCode }}</span>
          </td>
          <td>
            <img *ngIf="coolingSystem.imageUrl"
                 [src]="coolingSystem.imageUrl"
                 width="100"
                 class="image"/>
          </td>
          <td>
            <div class="text-sm text-gray-500">Kälteanlage</div>
            {{ coolingSystem.systemType }}
            <div>
              <img src="assets/plug.svg" alt="">
              45 kW
            </div>
            <div>
              <img src="assets/snowflake.svg" alt="">
              50 kW
            </div>
          </td>
          <td>
            <p-tag severity="success" value="OK"></p-tag>
          </td>
        </tr>
      </ng-template>

    </p-table>
  </p-panel>

</div>
