import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class KustecAdminGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot,
                        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    console.log('KustecAdminGuard');
    console.log(this.roles);
    if (this.roles.includes('kustec_admin')) {
      return Promise.resolve(true);
    } else {
      this.router.navigate(['/']);  // todo maybe redirect to error page
      return Promise.resolve(false);
    }
  }

}
