import {Component, OnInit} from '@angular/core';
import {MenubarModule} from "primeng/menubar";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-menubar',
  standalone: true,
  imports: [
    MenubarModule
  ],
  templateUrl: './menubar.component.html',
  styles: ``
})
export class MenubarComponent implements OnInit {

  items: MenuItem[] | undefined;

  ngOnInit(): void {
    this.items = [
      {
        label: 'Home',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/']
      },
      {
        label: 'Systems',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: ['/']
      },
      {
        label: 'Contact',
        icon: 'pi pi-fw pi-envelope',
        routerLink: ['/']
      },
      {
        label: 'Administration',
        icon: 'pi pi-fw pi-cog',
        routerLink: ['/administration'],
      }];
  }
}
