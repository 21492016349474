import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { SecurePageComponent } from './pages/secure-page/secure-page.component';
import { AuthGuard } from './guards/auth.guard';
import {SystemDetailPageComponent} from "./pages/system-detail-page/system-detail-page.component";
import {SystemEditPageComponent} from "./pages/system-edit-page/system-edit-page.component";
import {KustecAdminGuard} from "./guards/kustec-admin.guard";
import {AdministrationPageComponent} from "./pages/administration-page/administration-page.component";

const routes: Routes = [
  {
    path: 'secure',
    component: SecurePageComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'administration',
    component: AdministrationPageComponent,
    canActivate: [AuthGuard, KustecAdminGuard],
  },
  {
    path: 'edit/:systemNumber',
    component: SystemEditPageComponent,
    // canActivate: [AuthGuard, KustecAdminGuard],
  },
  {
    path: ':systemNumber',
    component: SystemDetailPageComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: '',
    component: StartPageComponent
  },
];

@NgModule( {
  imports: [RouterModule.forRoot( routes )],
  exports: [RouterModule]
} )
export class AppRoutingModule {
}
