import { Component } from '@angular/core';

@Component({
  selector: 'app-administration-page',
  standalone: true,
  imports: [],
  templateUrl: './administration-page.component.html',
  styles: ``
})
export class AdministrationPageComponent {

}
